[data-v-3525bcfc] .itemListGridNoPage .avue-crud .el-table {
  height: calc(100vh - 450px) !important;
  max-height: calc(100vh - 450px) !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.header-box[data-v-3525bcfc] {
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-box .box-card[data-v-3525bcfc] {
  background: rgb(25, 144, 255);
  height: 100px;
  font-size: 18px;
  color: #ffffff;
  width: calc(25% - 20px);
}
.header-box .box-card .margin-t-10[data-v-3525bcfc] {
  margin-top: 10px;
}
.shuttleBackBox[data-v-3525bcfc] {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shuttleBackBox .table-box[data-v-3525bcfc] {
  width: calc(100% - 280px);
}