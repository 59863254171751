.region-dialog[data-v-09a86041] .el-dialog {
  position: relative;
}
.region-dialog[data-v-09a86041] .el-dialog__body {
  overflow-y: auto;
  height: 400px;
}
.region-dialog[data-v-09a86041] .el-dialog__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20001;
}