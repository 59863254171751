.projectSet-container[data-v-11badfea] {
  background-color: #ffffff;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.projectSet-container[data-v-11badfea] .el-tabs__content {
  height: 100%;
}
.projectSet-container[data-v-11badfea] .el-tabs__content .el-tab-pane {
  height: 100%;
}
.projectSet-container[data-v-11badfea] .el-tabs__content .el-tab-pane .el-container {
  height: 100%;
}
.projectSet-container[data-v-11badfea] .el-tabs__content .el-tab-pane .el-container .common_tree_handle {
  height: 100%;
}
.projectSet-container[data-v-11badfea] .el-tabs__content .el-tab-pane .el-container .common_tree_handle .el-tree {
  height: calc(100% - 100px);
}
.projectSet-container .box-border-no[data-v-11badfea] {
  border-bottom: 0;
}
.list-box[data-v-11badfea] {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
}
.list-box[data-v-11badfea] .headLabel {
  display: none;
}
.list-box[data-v-11badfea] .headBtn {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.list-box[data-v-11badfea] .headBtn .badgeValue {
  padding: 0 3px;
}
[data-v-11badfea] .addCorePersonnelDialog .el-tree {
  height: 50vh !important;
  max-height: 50vh !important;
}
[data-v-11badfea] .addCorePersonnelDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
.formMain[data-v-11badfea] {
  width: 100%;
  height: calc(100vh - 200px) !important;
}
.formMain form[data-v-11badfea] {
  padding: 10px !important;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
}
.formTopic[data-v-11badfea] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-11badfea] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% - 55.8px);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
}
.shuttleBackBox .el-tabs[data-v-11badfea] {
  width: 100%;
}
.shuttleBackBox .table-box[data-v-11badfea] {
  width: calc(100% - 280px);
}
.shuttleBackBox[data-v-11badfea] .el-tabs__content {
  padding: 0;
}
.shuttleBackBox[data-v-11badfea] .el-tabs__nav-scroll {
  background: #fff;
  margin-right: 10px;
  padding-top: 15px;
  width: 180px;
}
.shuttleBackBox[data-v-11badfea] .el-tabs--left .el-tabs__header.is-left {
  margin-right: 0;
}
.shuttleBackBox[data-v-11badfea] .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  margin: 0 10px;
  border: none !important;
}
.shuttleBackBox[data-v-11badfea] .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
  border: none;
  background: #e2e5ea;
  border-radius: 6px;
}
.shuttleBackBox[data-v-11badfea] .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
.shuttleBackBox[data-v-11badfea] .el-tabs__item .iconfont {
  font-size: 18px;
  margin-right: 10px;
}
.shutleBack[data-v-11badfea] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-11badfea] {
  margin: 211px 12px;
}
.shutleTitle[data-v-11badfea] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-11badfea] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.deptBox[data-v-11badfea] {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.unitBox[data-v-11badfea] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.unitTitle[data-v-11badfea] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
.statisticsWrap[data-v-11badfea] {
  padding: 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.statisticsItem[data-v-11badfea] {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
  border-radius: 15px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.statisticsBox[data-v-11badfea] {
  width: 5vw;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 25px;
}
.statisticsBox_line[data-v-11badfea] {
  width: 2px;
  height: 70px;
  background: #ebebeb;
}
.statisticsBox_title[data-v-11badfea] {
  font-weight: 400;
  font-size: 14px;
}
.statisticsBox_num[data-v-11badfea] {
  font-weight: 400;
  font-size: 18px;
}