[data-v-ba5c65a2] .userSelectGridLayout .avue-crud .el-table {
  height: 50vh !important;
  max-height: 50vh !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-ba5c65a2] .userSelectTreeLayout .el-tree {
  height: 50vh !important;
  max-height: 50vh !important;
  overflow-y: scroll;
}